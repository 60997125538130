<template>
  <!-- About section start -->
  <section
    id="about-section"
    class="h-screen px-12 lg:px-32 py-16 border-t border-stone-500 relative"
  >
    <!-- Main text start -->
    <h1 class="uppercase text-5xl mb-4 font-semibold mt-32">What we offer</h1>
    <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
      Mesh Connect Coworking Space is dedicated to providing a comprehensive
      range of services and amenities to meet the diverse needs of our members.
      Our offerings include fully furnished workstations, private offices, and
      dedicated desks, all designed to create a productive and inspiring work
      environment. We provide high-speed internet, printing facilities, and 24/7
      access to ensure you have the flexibility and resources needed to succeed.
      Additionally, our meeting rooms are equipped with the latest technology to
      facilitate seamless presentations and collaborations.
    </p>

    <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
      Beyond the physical workspace, we offer a vibrant community and a host of
      value-added services. Our members enjoy access to exclusive events,
      workshops, and networking sessions, which are designed to foster
      connections and spur professional growth. Whether you're a freelancer
      looking for a quiet place to work, a startup seeking a collaborative
      environment, or a business in need of a professional setting for client
      meetings, Mesh Connect has you covered. Join us and take advantage of our
      thoughtfully curated offerings that support your business and personal
      development.
    </p>

    <!-- Circle start -->
    <div
      class="bg-[#DFC79B] h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"
    ></div>
    <!-- Circle end -->
  </section>
  <!-- About section end -->
</template>

<script>
export default {};
</script>

<style></style>
