<template>
  <!-- Services section start -->
  <section
    id="services-section"
    class="px-8 lg:px-24 py-16 relative overflow-hidden"
  >
    <!-- Text wrapper start -->
    <div class="flex gap-8 flex-col md:flex-row mt-24">
      <!-- Main text start -->
      <h1 class="uppercase text-5xl mb-4 font-semibold">Our Spaces</h1>
      <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
        At Mesh Connect Coworking Space, we offer a diverse range of
        thoughtfully designed workspaces to cater to your every need. From our
        fully-equipped private offices and collaborative open workstations to
        our exclusive spaces and private cubicle areas with four seats per
        table, we provide the perfect setting for productivity and creativity.
        Our state-of-the-art conference room, capable of seating 30 participants
        and featuring a 98-inch TV, is ideal for high-impact meetings and
        presentations. Each space is meticulously designed with modern
        amenities, high-speed internet, and ergonomic furniture to ensure a
        comfortable and efficient work environment. Experience the versatility
        and innovation of our spaces, tailored to support your professional
        journey.
      </p>
      <!-- Main text end -->
    </div>
    <!-- Text wrapper end -->

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <!-- Card 1 -->
      <div class="bg-white rounded-lg shadow-md overflow-hidden">
        <img
          class="w-full h-44 object-cover object-center"
          src="@/assets/spaces/conference.jpeg"
          alt="Image 1"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Conference</h2>
          <p class="text-stone-500">
            Our state-of-the-art conference room at Mesh Connect Coworking Space
            comfortably seats up to 30 participants and is equipped with a
            98-inch TV, perfect for presentations, video conferences, and
            collaborative sessions. Designed for maximum comfort and
            functionality, our conference room provides a professional
            environment to host your important meetings and events, ensuring a
            seamless and impactful experience for you and your attendees.
          </p>
        </div>
      </div>

      <!-- Card 2 -->
      <div class="bg-white rounded-lg shadow-md overflow-hidden">
        <img
          class="w-full h-44 object-cover object-center"
          src="@/assets/spaces/exclusive.jpeg"
          alt="Image 1"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Exclusive</h2>
          <p class="text-stone-500">
            Our Exclusive Space at Mesh Connect Coworking Space offers a
            premium, private area designed for those who seek a more secluded
            and luxurious work environment. Ideal for high-level meetings,
            confidential discussions, or focused work sessions, this space is
            equipped with top-notch amenities, ensuring comfort and
            productivity. Experience the perfect blend of privacy and
            sophistication in our Exclusive Space, tailored to meet the unique
            needs of discerning professionals and businesses.
          </p>
        </div>
      </div>

      <!-- Card 3 -->
      <div class="bg-white rounded-lg shadow-md overflow-hidden">
        <img
          class="w-full h-44 object-cover object-center"
          src="@/assets/spaces/private.jpeg"
          alt="Image 1"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Private Space</h2>
          <p class="text-stone-500">
            Our Private Cubicle Space at Mesh Connect Coworking Space provides a
            personal and quiet workspace tailored for individual productivity
            and focus. Each cubicle is designed with comfort and functionality
            in mind, featuring ergonomic furniture, ample storage, and
            high-speed internet access. Whether you're a freelancer, remote
            worker, or entrepreneur, our private cubicles offer the perfect
            balance of privacy and community, allowing you to concentrate on
            your tasks while being part of a vibrant coworking environment.
          </p>
        </div>
      </div>

      <!-- Card 4 -->
      <div class="bg-white rounded-lg shadow-md overflow-hidden">
        <img
          class="w-full h-44 object-cover object-center"
          src="@/assets/spaces/shared.jpeg"
          alt="Image 1"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Shared Space</h2>
          <p class="text-stone-500">
            Our Private Cubicle Space at Mesh Connect Coworking Space is
            thoughtfully designed for small teams and collaborative work,
            featuring tables with four ergonomic seats per table. This setup
            fosters teamwork and communication while maintaining a level of
            privacy and focus. Each cubicle is equipped with high-speed
            internet, ample storage, and comfortable seating, providing an ideal
            environment for productivity. Whether you're working on a group
            project or need a dedicated space for your team, our Private Cubicle
            Space offers the perfect blend of privacy and collaboration within a
            dynamic coworking community.
          </p>
        </div>
      </div>
    </div>

    <!-- Circle start -->
    <div
      class="bg-[#DFC79B] h-64 w-64 rounded-full absolute top-0 -right-20 mt-16 -z-20"
    ></div>
    <!-- Circle end -->
  </section>
  <!-- Services section end -->
</template>

<script>
export default {};
</script>

<style></style>
