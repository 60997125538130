<template>
  <!-- About section start -->
  <section
    id="about-section"
    class="h-screen px-12 lg:px-32 py-16 border-t border-stone-500 relative"
  >
    <!-- Main text start -->
    <h1 class="uppercase text-5xl mb-4 font-semibold mt-32">What We Do</h1>
    <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
      At Mesh Connect Coworking Space, we provide a dynamic and collaborative
      environment designed to inspire productivity and creativity. Our space is
      tailored for freelancers, entrepreneurs, remote workers, and small
      businesses looking for a professional and flexible work setting. With
      high-speed internet, modern office amenities, and comfortable
      workstations, we ensure you have everything you need to focus on your work
      and grow your business. Our vibrant community is the heart of Mesh
      Connect, offering ample opportunities for networking, collaboration, and
      learning from like-minded professionals.
    </p>

    <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
      We understand that work is not just about a desk and a chair. That's why
      we offer a range of services and facilities to support your work-life
      balance. From meeting rooms equipped with the latest technology to quiet
      zones for focused work, and common areas perfect for casual conversations
      and idea exchanges, Mesh Connect is designed to cater to your diverse
      needs. Additionally, our regular events, workshops, and networking
      sessions foster a sense of community and continuous learning. Join us at
      Mesh Connect Coworking Space and be a part of a community that’s committed
      to innovation, growth, and success.
    </p>

    <!-- Circle start -->
    <div
      class="bg-[#DFC79B] h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"
    ></div>
    <!-- Circle end -->
  </section>
  <!-- About section end -->
</template>

<script>
export default {};
</script>

<style></style>
