<template>
    <!-- Main section start -->
	<section class="h-screen px-10 lg:px-20 py-28 bg">
		<div class="w-full md:w-1/2 mt-10 h-full flex flex-col md:justify-center">
			<!-- Main text start -->
			<h1 class="uppercase text-4xl xl:text-5xl mb-4 mr-2 font-semibold">
				Welcome to Mesh Connect Co-Working Space!
			</h1>
			<p class="capitalize xl:w-1/2 text-stone-700">
				Where ideas come to life and connections thrive.
			</p>
			<!-- Main text end -->

			<!-- Social links start -->
			<div class="flex mt-3 gap-8 ">
				<a href="#">
					<i class="fa-brands fa-instagram socialIcon"></i>
				</a>
				<!-- <a href="#">
					<i class="fa-brands fa-linkedin socialIcon"></i>
				</a> -->
				<a href="#">
					<i class="fa-brands fa-facebook socialIcon"></i>
				</a>
				<!-- <a href="#">
					<i class="fa-brands fa-dribbble socialIcon"></i>
				</a> -->
				<a href="#">
					<i class="fa-brands fa-pinterest socialIcon"></i>
				</a>
			</div>
			<!-- Social links end -->
		</div>

		<!-- Photographer image start -->
		<!-- <div class="absolute bottom-0 right-0 overflow-hidden h-2/5 sm:h-1/2 md:h-3/5 lg:h-4/6 -z-50">
			<img src="@/assets/image.jpg" alt="img" class="h-full">
		</div> -->

		<!-- Photographer image end -->

		<!-- "More" button start -->
		<!-- <div class="w-full h-20 flex justify-start md:justify-center items-center">
			<a href="#about-section">
				<i class="fa-solid fa-arrow-down transition text-stone-100 text-xl bg-stone-700 rounded-full shadow p-4 hover:-translate-y-2 hover:shadow-xl"></i>
			</a>
		</div> -->
		<!-- "More" button end -->

	</section>
	<!-- Main section end -->
</template>

<script>
export default {

}
</script>

<style scope>
.bg {
	background-image: url('../assets/Home.png');
	background-repeat: no-repeat;
	background-size: cover;
	left:0;right:0;bottom:0;
}
</style>