<template>
  <!-- Blog post section -->
  <section class="px-6 md:px-12 lg:px-32 py-16">
    <!-- Blog post title -->
    <img
      :src="news.image"
      alt="News Image"
      class="w-full h-72 object-cover rounded-t-lg"
    />
    <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 md:mb-8 mt-14">
      {{ news.title }}
    </h1>

    <!-- Blog post metadata -->
    <div class="flex items-center text-gray-600 mb-6">
      <span class="mr-4">Published on {{ news.published }}</span>
      <span class="mr-4">•</span>
      <span>5 min read</span>
    </div>

    <!-- Blog post content -->
    <div class="prose max-w-none">
      <p v-html="formattedContent"></p>
    </div>
  </section>
  <!-- End of blog post section -->
</template>

<script>
export default {
  data() {
    return {
      news: {},
    };
  },
  created() {
    const newsList = JSON.parse(localStorage.getItem("newsList"));
    const id = parseInt(this.$route.params.id);
    this.news = newsList.find((news) => news.id === id);
  },
  computed: {
    formattedContent() {
      return this.news.content.replace(/\n/g, "<br>");
    },
  },
};
</script>
