<template>
  <!-- About section start -->
  <section
    id="news-section"
    class="px-6 md:px-12 lg:px-32 py-16 border-t border-gray-300 relative"
  >
    <!-- Main text start -->
    <div class="flex flex-col items-center mt-16">
      <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 md:mb-8">
        Latest News & Updates
      </h1>
      <p class="text-base md:text-lg max-w-prose text-center text-gray-600">
        Stay informed about our latest developments, news, and updates.
      </p>
    </div>

    <!-- News cards start -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
      <!-- News card -->
      <div
        class="bg-white rounded-lg shadow-md"
        v-for="news in newsList"
        :key="news.id"
      >
        <img
          :src="news.image"
          alt="News Image"
          class="w-full h-48 object-cover rounded-t-lg"
        />
        <div class="p-6">
          <h2 class="text-xl font-semibold mb-2">
            {{ news.title }}
          </h2>
          <p class="text-gray-700">
            {{ news.excerpt }}
          </p>
          <router-link
            :to="{ name: 'news-article', params: { id: news.id } }"
            class="text-blue-500 inline-block mt-4 hover:underline"
            v-bind="$attrs"
            @click="storeNewsList"
          >
            Read more
          </router-link>
        </div>
      </div>
    </div>

    <div
      class="bg-[#DFC79B] h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"
    ></div>
    <!-- News cards end -->
  </section>
  <!-- About section end -->
</template>

<script>
export default {
  name: "NewsUpdate",
  data() {
    return {
      newsList: [
        {
          id: 1,
          title:
            "Mesh Connect Coworking Space Introduces Tagum's Most Affordable Shared Workspace at Just 39 Pesos Per Hour",
          image: require("@/assets/shared/shared4.jpeg"),
          published: "May 31, 2024",
          excerpt:
            "Mesh Connect Coworking Space is proud to announce the launch of the most affordable shared workspace in Tagum, offering unparalleled value at just 39 pesos per hour. This new initiative is designed to make professional workspaces accessible to freelancers, remote workers, and small businesses who are looking for a cost-effective solution without compromising on quality...",
          content: `Mesh Connect Coworking Space is proud to announce the launch of the most affordable shared workspace in Tagum, offering unparalleled value at just 39 pesos per hour. This new initiative is designed to make professional workspaces accessible to freelancers, remote workers, and small businesses who are looking for a cost-effective solution without compromising on quality.
            \nThe shared area at Mesh Connect Coworking Space provides a vibrant and collaborative environment, perfect for individuals who thrive in a community setting. With comfortable seating, high-speed internet, and access to modern office amenities, this space ensures that productivity is maximized while keeping costs to a minimum.
            \nLocated in the heart of Tagum, Mesh Connect's shared area not only stands out for its affordability but also for the quality of service and facilities offered. Whether you're looking for a place to work for an hour or planning to spend the entire day, our flexible hourly rates cater to your needs, making professional workspaces more accessible than ever before. Join us at Mesh Connect Coworking Space and experience the perfect blend of affordability, community, and productivity.`,
        },
        {
          id: 2,
          title:
            "Mesh Connect Coworking Space Unveils State-of-the-Art Conference Area with 98-Inch TV",
          image: require("@/assets/conference/confe1.jpeg"),
          published: "May 10, 2024",
          excerpt:
            "Mesh Connect Coworking Space is excited to announce the grand opening of its new, state-of-the-art conference area, designed to elevate the meeting experience for businesses and professionals in Tagum. This spacious facility comfortably accommodates up to 30 participants, making it ideal for large gatherings, corporate meetings, workshops, and seminars...",
          content: `Mesh Connect Coworking Space is excited to announce the grand opening of its new, state-of-the-art conference area, designed to elevate the meeting experience for businesses and professionals in Tagum. This spacious facility comfortably accommodates up to 30 participants, making it ideal for large gatherings, corporate meetings, workshops, and seminars.
            \nThe highlight of the conference area is its impressive 98-inch TV, equipped with the latest technology to support high-definition presentations, video conferences, and multimedia displays. This feature ensures that every presentation is delivered with crystal-clear visuals and sound, creating an engaging and impactful experience for all attendees. The advanced audiovisual setup is complemented by user-friendly connectivity options, allowing seamless integration with various devices.
            \nIn addition to the cutting-edge technology, the conference area is designed with comfort and functionality in mind. Ergonomic seating arrangements ensure that participants remain comfortable throughout extended sessions, while the spacious layout allows for easy movement and interaction. The ambiance is further enhanced by modern decor and professional lighting, creating a conducive environment for productive discussions and collaborative efforts.
            \nMesh Connect Coworking Space understands the importance of providing a professional and versatile venue for its members and the broader business community. The new conference area is available for booking on an hourly or daily basis, offering flexible options to suit different needs and budgets. Whether you're hosting a strategic planning session, a training workshop, or a client presentation, this facility provides the perfect setting to achieve your goals.
            \nWith the introduction of this premium conference area, Mesh Connect Coworking Space continues to demonstrate its commitment to supporting the growth and success of businesses in Tagum. By offering top-tier facilities and amenities, Mesh Connect aims to be the preferred choice for professionals seeking a reliable and high-quality coworking environment. Experience the best in conference solutions at Mesh Connect and take your meetings to the next level.`,
        },
        {
          id: 3,
          title:
            "Mesh Connect Coworking Space Enhances Reliability with New Power Generator Set",
          image: require("@/assets/private/private1.jpeg"),
          published: "May 10, 2024",
          excerpt:
            "Mesh Connect Coworking Space is thrilled to announce the installation of a new power generator set, ensuring uninterrupted power supply and enhancing the reliability of its facilities. This strategic investment underscores Mesh Connect’s commitment to providing a seamless and productive work environment for its members, even during unexpected power outages...",
          content: `Mesh Connect Coworking Space is thrilled to announce the installation of a new power generator set, ensuring uninterrupted power supply and enhancing the reliability of its facilities. This strategic investment underscores Mesh Connect’s commitment to providing a seamless and productive work environment for its members, even during unexpected power outages.
            \nThe new generator set is a high-capacity unit designed to provide instant backup power, guaranteeing that all office equipment, including high-speed internet, lighting, and air conditioning, remain fully operational during outages. This ensures that members can continue their work without any disruptions, maintaining productivity and meeting critical deadlines regardless of external power issues.
            \nIn addition to supporting essential office operations, the generator set also powers the state-of-the-art conference area, private offices, and shared workspaces. This comprehensive coverage means that all aspects of the coworking space remain functional, providing peace of mind to freelancers, remote workers, and businesses relying on Mesh Connect for a dependable work environment. The generator's quiet operation minimizes noise pollution, ensuring a comfortable and focused atmosphere for all users.
            \nMesh Connect Coworking Space's decision to invest in a power generator set reflects its dedication to delivering superior service and support to its community. By proactively addressing potential power challenges, Mesh Connect ensures that its members can work confidently, knowing that their work environment is both stable and secure. This upgrade is particularly beneficial for businesses conducting critical operations or hosting important meetings, where power stability is paramount.
            \nWith the installation of the new power generator set, Mesh Connect Coworking Space continues to lead the way in providing top-notch facilities and services in Tagum. This enhancement not only boosts the reliability of the coworking space but also reinforces its reputation as a premier choice for professionals seeking a resilient and well-equipped workspace. Join Mesh Connect and experience uninterrupted productivity and excellence in coworking.`,
        },
      ],
    };
  },
  methods: {
    storeNewsList() {
      localStorage.setItem("newsList", JSON.stringify(this.newsList));
    },
  },
};
</script>
