<template>
  <!-- Portfolio section start -->
  <section id="portfolio-section" class="px-4 lg:px-8 py-16 relative">
    <!-- Text wrapper start -->
    <div class="flex gap-8 flex-col md:flex-row mt-20">
      <!-- Main text start -->
      <h1 class="uppercase text-5xl mb-4 font-semibold">Gallery</h1>
      <!-- <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit dolores distinctio reiciendis obcaecati ea.
            </p> -->
      <!-- Main text end -->
    </div>
    <!-- Text wrapper end -->

    <!-- Images grid start -->
    <div class="grid-cols-2 md:grid-cols-5 grid mb-8 gap-4">
      <div class="grid grid-cols-1 md:hidden gap-4">
        <img src="@/assets/shared/shared1.jpeg" alt="1" class="rounded-xl" />
        <img
          src="@/assets/exclusive/exclusive2.jpeg"
          alt="2"
          class="rounded-xl"
        />
        <img src="@/assets/conference/confe3.jpeg" alt="3" class="rounded-xl" />
        <img src="@/assets/private/private6.jpeg" alt="4" class="rounded-xl" />
        <img src="@/assets/conference/confe5.jpeg" alt="5" class="rounded-xl" />
      </div>
      <div class="grid grid-cols-1 md:hidden gap-4">
        <img src="@/assets/shared/shared3.jpeg" alt="6" class="rounded-xl" />
        <img
          src="@/assets/exclusive/exclusive1.jpeg"
          alt="7"
          class="rounded-xl"
        />
        <img src="@/assets/conference/confe8.jpeg" alt="8" class="rounded-xl" />
        <img src="@/assets/private/private3.jpeg" alt="9" class="rounded-xl" />
        <img
          src="@/assets/conference/confe1.jpeg"
          alt="10"
          class="rounded-xl"
        />
      </div>

      <div class="hidden md:flex flex-col gap-4">
        <img src="@/assets/shared/shared1.jpeg" alt="1" class="rounded-xl" />
        <img
          src="@/assets/exclusive/exclusive2.jpeg"
          alt="2"
          class="rounded-xl"
        />
      </div>
      <div class="hidden md:flex flex-col gap-4">
        <img src="@/assets/conference/confe3.jpeg" alt="1" class="rounded-xl" />
        <img src="@/assets/private/private6.jpeg" alt="2" class="rounded-xl" />
      </div>
      <div class="hidden md:flex flex-col gap-4">
        <img src="@/assets/conference/confe5.jpeg" alt="2" class="rounded-xl" />
        <img src="@/assets/shared/shared3.jpeg" alt="2" class="rounded-xl" />
      </div>
      <div class="hidden md:flex flex-col gap-4">
        <img
          src="@/assets/exclusive/exclusive1.jpeg"
          alt="1"
          class="rounded-xl"
        />
        <img src="@/assets/conference/confe8.jpeg" alt="2" class="rounded-xl" />
      </div>
      <div class="hidden md:flex flex-col gap-4">
        <img src="@/assets/private/private3.jpeg" alt="1" class="rounded-xl" />
        <img src="@/assets/conference/confe1.jpeg" alt="2" class="rounded-xl" />
      </div>
    </div>
    <!-- Images grid end -->

    <!-- "More" link start -->
    <a href="#" class="text-end">
      <p class="font-semibold text-lg group relative">
        <span>Show more photos </span>
        <i class="fa-solid fa-arrow-right"></i>
      </p>
    </a>
    <!-- "More" link end -->

    <!-- Circle start -->
    <div
      class="bg-[#DFC79B] h-64 w-64 rounded-full absolute top-0 left-0 mt-16 -z-20"
    ></div>
    <!-- Circle end -->
  </section>
  <!-- Portfolio section end -->
</template>

<script>
export default {};
</script>

<style></style>
