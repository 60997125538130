<template>
  <!-- About section start -->
  <section
    id="about-section"
    class="h-screen px-12 lg:px-32 py-16 border-t border-stone-500 relative"
  >
    <!-- Main text start -->
    <h1 class="uppercase text-5xl mb-4 font-semibold mt-24">Social media</h1>
    <div class="flex flex-col space-y-7 mt-6">
      <!-- Facebook -->
      <div class="flex items-center">
        <i class="fab fa-facebook-f fa-2x text-blue-500"></i>
        <a
          href="https://www.facebook.com/meshconnect.tgm"
          target="_blank"
          rel="noopener noreferrer"
          class="ml-2 text-stone-500 hover:text-blue-500"
          >Facebook</a
        >
      </div>
      <!-- Twitter -->
      <div class="flex items-center">
        <i class="fab fa-twitter fa-2x text-blue-500"></i>
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          class="ml-2 text-stone-500 hover:text-blue-500"
          >Twitter</a
        >
      </div>
      <!-- Instagram -->
      <div class="flex items-center">
        <i class="fab fa-instagram fa-2x text-pink-500"></i>
        <a
          href="https://www.instagram.com/meshconnect.tgm"
          target="_blank"
          rel="noopener noreferrer"
          class="ml-2 text-stone-500 hover:text-pink-500"
          >Instagram</a
        >
      </div>
      <!-- LinkedIn -->
      <div class="flex items-center">
        <i class="fab fa-linkedin-in fa-2x text-blue-500"></i>
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          class="ml-2 text-stone-500 hover:text-blue-500"
          >LinkedIn</a
        >
      </div>
    </div>

    <!-- Circle start -->
    <div
      class="bg-[#DFC79B] h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"
    ></div>
    <!-- Circle end -->
  </section>
  <!-- About section end -->
</template>

<script>
export default {};
</script>

<style></style>
