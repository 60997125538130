<template>
  <section id="portfolio-section" class="px-6 lg:px-24 py-24 relative">
    <!-- Text wrapper start -->
    <div class="flex gap-8 flex-col md:flex-row mt-20">
      <!-- Main text start -->
      <h1 class="uppercase text-5xl mb-4 font-semibold">Exclusive Area</h1>
      <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
        At Mesh Connect Coworking Space, we offer a diverse range of
        thoughtfully designed workspaces to cater to your every need. From our
        fully-equipped private offices and collaborative open workstations to
        our exclusive spaces and private cubicle areas with four seats per
        table, we provide the perfect setting for productivity and creativity.
        Our state-of-the-art conference room, capable of seating 30 participants
        and featuring a 98-inch TV, is ideal for high-impact meetings and
        presentations. Each space is meticulously designed with modern
        amenities, high-speed internet, and ergonomic furniture to ensure a
        comfortable and efficient work environment. Experience the versatility
        and innovation of our spaces, tailored to support your professional
        journey.
      </p>
      <!-- Main text end -->
    </div>
    <!-- Text wrapper end -->
    <div
      class="max-w-screen-xl mx-auto grid gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2"
    >
      <div class="image-container">
        <img
          src="@/assets/exclusive/exclusive1.jpeg"
          alt="Image 1"
          class="rounded-xl"
        />
      </div>
      <div class="image-container">
        <img
          src="@/assets/exclusive/exclusive2.jpeg"
          alt="Image 2"
          class="rounded-xl"
        />
      </div>
    </div>

    <!-- Circle start -->
    <div
      class="bg-[#DFC79B] h-64 w-64 rounded-full absolute top-0 left-0 mt-16 -z-20"
    ></div>
    <!-- Circle end -->
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.image-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
