<template>
    <!-- Contact section start -->
	<section id="contact-section" class="h-screen px-12 lg:px-32 py-16 relative">
        <!-- Main text start -->
		<h1 class="uppercase text-5xl mb-4 font-semibold mt-24">
			Call us
		</h1>
		<!-- Main text end -->

        <!-- Form start -->
        <div class="flex flex-col w-full text-left">
            <span>0926 670 3691</span>
            Door 2 JOMABO Bldg, Sison Subd.,, Tagum City, Philippines
        </div>

        <!-- Form end -->

        <!-- Circle start -->
        <div class="bg-[#DFC79B] h-44 w-44 md:h-52 md:w-52 rounded-full absolute -top-20 left-0 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Contact section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>