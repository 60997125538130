<template>
  <!-- Navbar start -->
  <nav class="w-screen fixed bg-white p-4 lg:px-20 flex justify-between z-50">
    <!-- Logo start -->
    <div class="h-full flex flex-col justify-center">
      <img src="@/assets/banner.png" alt="logo" class="w-40 mt-1" />
    </div>
    <!-- Logo end -->

    <!-- Toggler start -->
    <button role="menubar" class="lg:hidden" @click="navToggle()">
      <i class="fa-solid fa-bars text-2xl"></i>
    </button>
    <!-- Toggler end -->

    <!-- Menu content start -->
    <div
      class="absolute left-0 right-0 translate-y-16 bg-opacity-90 bg-white md:bg-none md:bg-opacity-0 shadow hidden sm:hidden md:hidden lg:flex flex-col gap-4 items-center p-4 md:flex-row md:static md:shadow-none md:translate-y-0"
      role="menu"
      aria-expanded="false"
    >
      <!-- Links start -->
      <router-link to="/" role="menuitem" class="px-4 py-2 routerBtn">
        Home
      </router-link>

      <div class="dropdown hover:bg-[#DFC79B] rounded-lg inline-block relative">
        <div class="py-2 px-4 rounded-lg inline-flex items-center">
          <span class="mr-1">About Us</span>
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
        <ul class="dropdown-menu absolute hidden pt-2 rounded-md z-10">
          <li>
            <router-link to="/about/what-we-do">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                What We Do
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/about/what-we-offer">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                What We Offer
              </a>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="dropdown hover:bg-[#DFC79B] rounded-lg inline-block relative">
        <div class="py-2 px-4 rounded-lg inline-flex items-center">
          <span class="mr-1">Services Offered</span>
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
        <ul class="dropdown-menu absolute hidden pt-2 rounded-md z-10">
          <li>
            <router-link to="/services/spaces">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Spaces
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/services/shared-area">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Shared Area
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/services/private-area">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Private Area
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/services/exclusive-area">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Exclusive Area
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/services/conference">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Conference Rooms
              </a>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="dropdown hover:bg-[#DFC79B] rounded-lg inline-block relative">
        <div class="py-2 px-4 rounded-lg inline-flex items-center">
          <span class="mr-1">Who We Are</span>
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
        <ul class="dropdown-menu absolute hidden pt-2 rounded-md z-10">
          <li>
            <router-link to="/who-we-are/news-updates">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Latest News & Updates
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/who-we-are/portfolio">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Portfolio
              </a>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/who-we-are/blog-post">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Blog Posts
              </a>
            </router-link>
          </li> -->
        </ul>
      </div>

      <router-link
        to="/contact"
        class="dropdown hover:bg-[#DFC79B] rounded-lg inline-block relative"
      >
        <div class="py-2 px-4 rounded-lg inline-flex items-center">
          <span class="mr-1">Contact Us</span>
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
        <ul class="dropdown-menu absolute hidden pt-2 rounded-md z-10">
          <li>
            <router-link to="/contact/call-us">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Call Us
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/contact/email-us">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Email Us
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/contact/social-media">
              <a
                class="bg-gray-100 hover:bg-[#DFC79B] py-2 px-4 block whitespace-no-wrap"
              >
                Social Media
              </a>
            </router-link>
          </li>
        </ul>
      </router-link>
    </div>
    <!-- Menu content end -->
  </nav>
  <!-- Navbar end -->
</template>

<script>
export default {
  methods: {
    // Toogle navbar mode
    navToggle() {
      let menu = document.querySelector("[role='menu']");
      let isExpanded = menu.getAttribute("aria-expanded");
      menu.setAttribute("aria-expanded", !isExpanded);
      menu.classList.toggle("hidden");
      menu.classList.toggle("flex");
    },
  },
};
</script>

<style scope>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
