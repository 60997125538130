import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/MainView.vue";
import AboutView from "../views/AboutView.vue";
import ServicesView from "../views/ServicesView.vue";
import WhoWeAreView from "../views/WhoWeAreView.vue";
import ContactView from "../views/ContactView.vue";
import WhatWeDoSection from "../sections/WhatWeDoSection.vue";
import WhatWeOfferSection from "../sections/WhatWeOfferSection";
import PortfolioSection from "@/sections/PortfolioSection.vue";
import ServiceSection from "@/sections/ServiceSection.vue";
import SharedAreaSection from "@/sections/SharedAreaSection.vue";
import PrivateAreaSection from "@/sections/PrivateAreaSection.vue";
import ExclusiveSection from "@/sections/ExclusiveSection.vue";
import ConferenceSection from "@/sections/ConferenceSection.vue";
import NewsUpdatesSection from "@/sections/NewsUpdatesSection.vue";
import ContactSection from "@/sections/ContactSection.vue";
import CallUsSection from "@/sections/CallUsSection.vue";
import SocialMediaSection from "@/sections/SocialMediaSection.vue";
import BlogPostSection from "@/sections/BlogPostSection.vue";
import NewsArticleSection from "@/sections/NewsArticleSection.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
    children: [
      {
        path: "/about/what-we-do",
        component: WhatWeDoSection,
      },
      {
        path: "/about/what-we-offer",
        component: WhatWeOfferSection,
      },
    ],
  },
  {
    path: "/who-we-are",
    name: "who-we-are",
    component: WhoWeAreView,
    children: [
      {
        path: "/who-we-are/news-updates",
        component: NewsUpdatesSection,
      },
      {
        path: "/who-we-are/news-updates/:id", // New route
        name: "news-article",
        component: NewsArticleSection,
      },
      {
        path: "/who-we-are/portfolio",
        component: PortfolioSection,
      },
      {
        path: "/who-we-are/blog-post",
        component: BlogPostSection,
      },
    ],
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    children: [
      {
        path: "/contact/call-us",
        component: CallUsSection,
      },
      {
        path: "/contact/email-us",
        component: ContactSection,
      },
      {
        path: "/contact/social-media",
        component: SocialMediaSection,
      },
    ],
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
    children: [
      {
        path: "/services/spaces",
        component: ServiceSection,
      },
      {
        path: "/services/shared-area",
        component: SharedAreaSection,
      },
      {
        path: "/services/private-area",
        component: PrivateAreaSection,
      },
      {
        path: "/services/exclusive-area",
        component: ExclusiveSection,
      },
      {
        path: "/services/conference",
        component: ConferenceSection,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
